:root {
	--primary: #B12326;
	--primaryDim: #F3DEDE;
	--primaryLight: #CE112F;
	--secondary: #333333;
	--secondaryDim: #5C5C5C;
	--bright: #ffffff;
	--dark: #000000;
	--iconsDim: #999999;
	--icons: #D89192;
	--background: #EDEDED;
	--backgroundDark: #494949;
	--shadow: '#00000066';
	--delimiter: #D8D8D8;
	--backgroundLight: #CCCCCC;
}