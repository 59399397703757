.toolbar {
  .buttonsContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &> div {
      margin: 10px;
      cursor: pointer;
    }
  }
  .toolbarTitle {
    width: 100%;
  }
  .toolbarCenter {
    position: absolute;
    bottom: calc(50% - 125px);
    z-index: 100;
    left: 0;
    right: 0;
    text-align: center;
  }
  .toolbarCenterTest {
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
    text-align: center;
    bottom: calc(50% - 60px);
  }
}
.TEST {
  border-radius: 10px;
}

.CallBtn {
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: rgba($color: #fff, $alpha: 0.18);
  justify-content: center;
  align-items: center;
  &.PhoneBtn {
    background-color: var(--primary);
  }
}
