.AppointmentTypeModal {
  .Content {
    width: 532px;
  }
}

.AppointmentType {
  padding: 20px 16px 15px 16px;
  min-width: 500px;
  .Info {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    .Title {
      font-size: 15px;
      line-height: 18px;
      font-weight: bold;
    }
    .Description {
      margin-top: 7px;
      border: 1px solid var(--primary);
      border-radius: 5px;
      padding: 10px;
      span {
        font-size: 13px;
        line-height: 18px;
        color: var(--secondary);
      }
      .Bold {
        font-weight: bold;
      }
    }
  }
  & > .CheckButton:not(:first-child) {
    margin-top: 5px;
  }
  & > .CheckBox {
    margin-top: 20px;
  }
  & .BButton {
    margin-top: 20px;
  }
}